<template>
<div class="widget-min-box" :widget-id="widget.id" :widget-type="widget.type">
    <div :class="'tv-iframe-wrapper ' + div">
        <iframe id="tv-iframe" allowfullscreen="" :src="`${this.$helpers.tv4_url()}/?symbol=${symbol}&${ !full ? 'full=1' : ''}&${new Date().getTime()}`" style="border:none;width: 100%;display: inline-block;height: 750px;"></iframe>
    </div>
</div>
</template>

<style lang="scss" scoped>
.tv-iframe-wrapper {
    width: 100%;
}
</style>

<script>
export default {
    name: 'TradingView',
    props: ['widget', 'data', 'editable', 'wrapper', 'full'],
    components: {},
    data: function () {
        return {
            symbol: null,
            div: null
        }
    },
    mounted() {
        this.$watch('widget', function (newValue, oldValue) {
            if (Object.keys(newValue).length && (typeof oldValue == 'undefined' || !Object.keys(oldValue).length)) {
                this.symbol = this.widget.data.symbol;
                this.div = 'tv' + Math.floor(Math.random() * 10000000) + 2000000;
            }
        }, { immediate: true });
    },
    methods: {
        open_settings_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_save_settings_' + this.widget.id + '_' + new Date().getTime();

            let modal_data = {
                modal: 'widget_settings_modal',
                callbackEvent,
                component: 'Settings/TradingView.vue',
                config: {
                    data: {
                        title: this.widget.title,
                        symbol: this.widget.data.symbol,
                    }
                }
            };

            modal_data = this.$helpers.unbindObject(modal_data);

            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (settings) => {

                this.widget.title = settings.title;
                this.widget.data.symbol = settings.symbol;

                let widget = this.$helpers.unbindObject(this.widget);

                this.$helpers.saveWidget(widget).then(() => {
                    this.$helpers.getWidgets({ widget_id: this.widget.id }).then((widget) => {
                        this.symbol = widget[0].widget.symbol;
                    });
                });

                //reload the TradingViewVue
                let url = this.$helpers.tv4_url() + '/?symbol=' + settings.symbol + '&token=' + this.$store.state.accessToken + '&' + new Date().getTime();

                var m = document.getElementsByClassName(this.div);
                m[0].innerHTML = '<iframe id="tv-iframe" src="' + url.toString() + '" style="border:none;width: 100%;display: inline-block;height: 750px;"></iframe>';

            });
        },
    },
}
</script>
