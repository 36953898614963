<template>
<form @submit.prevent="">
    <slot v-if="widgetLoad">
        <div class="text-center p-2">
            <div class="widget-loading"></div>
        </div>
    </slot>
    <slot v-else>
        <TradingView :widget="widget" :data="widgetData" :key="data.refresh" :editable="true" />
    </slot>
</form>
</template>

<style lang="scss" scoped>
#widgets-modal .modal-content,
.modal[role="dialog"] .modal-content {
    max-width: 100% !important;
}
</style>

<script>
// این کامپوننت مودال افزودن تحلیل جدید می باشد
import TradingView from '@/components/TradingView.vue'

export default {
    name: 'NewTechnical',
    props: ['data'],
    components: {
        TradingView
    },
    data: function () {
        return {
            widget: {},
            widgetData: {},
            widgetLoad: true,
        }
    },
    mounted() {
        this.$helpers.getWidgets({ widget_type: 'trading-view', store: false }).then((widgets) => {
            let widget = widgets[0];
            this.widgetLoad = false;

            this.widget = widget.widget;
            this.widgetData = widget.data;
        });
    },
    methods: {
        // این متد جهت باز کردن مودال شاخصیاب استفاده میشود
        openDiagram() {
            let callbackEvent = 'jQuery.hColumns' + new Date().getTime();
            localStorage.setItem('jQuery_hColumns_event', callbackEvent);

            this.$root.$on(callbackEvent, (data) => {
                this.$root.$off(callbackEvent);
                $('.tv-iframe-wrapper').html(`<iframe id="tv-iframe" src="${this.$helpers.tv4_url()}/?symbol=${data.symbol}&item_id=${data.item_id}" style="border:none;width: 100%;display: inline-block;height: 750px;"></iframe>`);
            });

            $('#indicator-modal').show();
            $('#indicator-modal #columns2').html('');
            this.$helpers.diagrams_render();
        },
        // این متد جهت فول اسکرین کردن تریدینگ ویو استفاده میشود
        fullScreen(event) {
            // $(event).target.parents().eq(3).find('.iframe-wrapper-box').toggleClass('fullscreen-trading'); 
            $(".dashboard-widget-tabs").toggleClass('fullscreen-trading');
        },
    },
}
</script>
